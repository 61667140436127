import React, { useRef, useState, useEffect,useCallback,useImperativeHandle } from 'react';
import { Button, Tooltip, Icon, Input } from "@tencent/universe-ui/build/lib";
import { Stage, Layer, Image, Line } from 'react-konva';
import useImage from 'use-image';
import useSmoothScroll from 'react-smooth-scroll-hook';
import { getScaleRatio, getImgNatural, getSum,throttle,debounce } from '../../utils/index.js';
const NumberReg = /^[0-9]+\.{0,1}[0-9]{0,2}$/;
const LayerImage = ({ src, width, height, x, y }) => {
  const [image] = useImage(src);
  return <Image
    image={image}
    width={width}
    height={height}
    y={y}
    x={x} />;
};
const ThumbnailPreview = ({
  rects = [],
  isShow = true,
  showImgList = true,
  imageList = [],
  imageUrl,
  curItem,
  style,
  showZoomInIcon = true,
  showZoomOutIcon = true,
  showLeftIcon = true,
  showRightIcon = true,
  errorMsg = '图片无法显示',
  onSize,
  selectRect,
  onUpload = () => { },
  onSelect = () => { },
  onPageChange,
  cRef
}) => {
  useImperativeHandle(cRef, () => ({
    onPreChange: (curImg) => {
      console.log('handleSetStageWidth onPreChange',curImg,imageList);
      setCurDeg(0);
      handleSetStageWidth(curImg,{
        ...sizes,
        width: 100,
        height: 100,
        deg: 0,
      },imageBoxRef?.current,curDeg)
    }
  }));
  const [width, setWidth] = useState(0);
  const [loading, setLoading] = useState(true);
  const [targetHeight, setTargetHeight] = useState(0);
  const [canvasLayout, setCanvasLayout] = useState('center'); //  center || start
  const [isError, setIsError] = useState(false);
  const [showPdfPageControl, setShowPdfPageControl] = useState(false);
  const [imgList, setImgList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputVal, setInputVal] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [curDeg,setCurDeg] = useState(0);
  const [rotateInfo,setRotateInfo] = useState({
    rotation: 0,
    offsetX: 0,
    offsetY:0
  })
  const imageBoxRef = useRef(document.body);

  const { scrollTo } = useSmoothScroll({
    ref: imageBoxRef,
    speed: 100,
    direction: 'y',
  });

  const [sizes, setSizes] = useState({
    originHeight:0,
    originWidth:0,
    width: 100,
    height: 100,
    deg: 0,
  });

  const getRectRotationInfo = (curDeg,targetHeight = targetHeight ,width = width) => {
    if(curDeg % 4 === 1 || curDeg % 4 === -3 ){  //  右
      setRotateInfo({
        width: targetHeight,
        height: width,
        rotation: 90,
        offsetX: 0,
        offsetY: targetHeight
      })
    }else if(curDeg % 4 == 3 || curDeg % 4 == -1){ //  左
      setRotateInfo({
        width: targetHeight,
        height: width,
        rotation: -90,
        offsetX: width ,
        offsetY: 0
      })
    }else if(curDeg % 4 === 0 ){  //  上
      setRotateInfo({
        width: width,
        height: targetHeight,
        rotation: 0,
        offsetX: 0,
        offsetY: 0,
      })
    }else if(curDeg % 4 === 2 || curDeg % 4 === -2){  // 下
      setRotateInfo({
        width: width,
        height: targetHeight,
        rotation: -180,
        offsetX: width,
        offsetY: targetHeight
      })
    }
  }

  useEffect(() => {
    async function func() {
      if (imageList?.length <= 0) return;
      scrollToTarget(currentPage);
      setInputVal(currentPage);
    }
    func();
  }, [currentPage]);

  useEffect(() => {
    getRectRotationInfo(curDeg,targetHeight,width);
    handleSetLayout(curDeg,targetHeight,width);
  }, [curDeg]);

  async function handleSetLayout(curDeg,targetHeight,width){
    let _containerWidth = imageBoxRef?.current?.clientWidth
    let _containerHeight = imageBoxRef?.current?.clientHeight
    let _canvasLayout = ''
    if(curDeg % 2 === 0){ //   正方向
      if(_containerWidth > width){
        _canvasLayout += ' just-center'
      }
      if(_containerHeight > targetHeight){
        _canvasLayout += ' align-center'
      }
    }else if (Math.abs(curDeg % 2) === 1 ){  //  左右方向
      if(_containerWidth > targetHeight){
        _canvasLayout += ' just-center'
      }
      if(_containerHeight > width){
        _canvasLayout += ' align-center'
      }
    }
    setCanvasLayout(_canvasLayout);
  }

  async function handleSetStageWidth(imageUrl, sizes,imageBoxRef,curDeg) {
    
    const curImg = imageList?.length > 0 ? imageList[0] : imageUrl;
    if(!curImg){
      setIsError(true);
      return;
    }
    console.log('handleSetStageWidth',imageUrl,imageList)
    setLoading(true);
    try {
      let _containerWidth = imageBoxRef?.clientWidth
      let _containerHeight = imageBoxRef?.clientHeight
      setSizes(sizes);
      setIsError(false);
      let isPdf= imageList?.length > 0 ;
      let _imageList = imageList?.length > 0 ? imageList : [imageUrl];
      setShowPdfPageControl(imageList?.length > 1);
      let res  = await getImgNatural(curImg,isPdf);
      console.log('handleSetStageWidth',res);
      
      // 只取第一张用于计算图片尺寸
      const result = getScaleRatio({
        ImageBox: {
          h: _containerHeight,
          w: _containerWidth,
        },
        Image: {
          h: curDeg % 2 === 0 ? res.height : res.width,
          w:  curDeg % 2 === 0 ? res.width : res.height
        },
      });
      const _width = curDeg % 2 === 0 ? result.ImageWidth * (sizes.width / 100) : result.ImageHeight * (sizes.height / 100)
      const _height = curDeg % 2 === 0 ? result.ImageHeight * (sizes.height / 100) : result.ImageWidth * (sizes.width / 100)
      // pdf img list
      let ImgList = _imageList.map(img => {
        return {
          src: img,
          width: result.Ratio * res.width * (sizes.width / 100),
          height: result.Ratio * res.height * (sizes.height / 100),
        }
      })
      ImgList = ImgList.map((i, index) => {
        return {
          ...i,
          x: 0,
          y: getSum(ImgList.slice(0, index).map(i => i.height)) + index * 8 || 0  //  pdf 之间间隔8
        }
      })
      let targetHeight = getSum(ImgList.map(i => i.height));
      setTotalPage(ImgList.length);
      setImgList(ImgList);
      handleSetLayout(curDeg,targetHeight,_width);
      getRectRotationInfo(curDeg,targetHeight,_width);
      onSize && onSize({
        originHeight: res.height,
        originWidth: res.width,
        width: _width,
        height: _height
      });
      setWidth(_width);
      setTargetHeight(targetHeight)
      setLoading(false);
    } catch (e) {
      console.log('size error', e)
      setIsError(true);
      setLoading(false);
    }
  }

  const onDeg = (deg) => {
    let cur = curDeg + deg
    setCurDeg(cur);
  }

  const onZoomOut = () => {
    const newWidth = sizes.width - 10;
    if (newWidth > 20) {
      let newSizes = {
        ...sizes,
        width: newWidth,
        height: newWidth,
      }
      handleSetStageWidth(imageUrl, newSizes,imageBoxRef?.current,curDeg)
    }
  };

  const onZoomIn = () => {
    const newWidth = sizes.width + 10;
    if (newWidth <= 180) {
      let newSizes = {
        ...sizes,
        width: newWidth,
        height: newWidth,
      }
      handleSetStageWidth(imageUrl, newSizes,imageBoxRef?.current,curDeg)
    }
  };
  // 放大按钮
  const zoomInIcon = (
    <svg viewBox="0 0 16 16" fill="currentColor">
      <path d="M11.7803495,2.5879595 C14.0781495,4.8857295 14.2959495,8.4758195 12.4338495,11.0186495 L15.3158495,13.9016495 L13.9016495,15.3158495 L11.0186495,12.4338495 C8.4758195,14.2959495 4.8857295,14.0781495 2.5879595,11.7803495 C0.0495475,9.2419395 0.0495475,5.1263595 2.5879595,2.5879595 C5.1263595,0.0495475 9.2419395,0.0495475 11.7803495,2.5879595 Z M4.0021695,4.0021695 C2.2448095,5.7595295 2.2448095,8.6087695 4.0021695,10.3661495 C5.7595295,12.1234495 8.6087695,12.1234495 10.3661495,10.3661495 C12.1234495,8.6087695 12.1234495,5.7595295 10.3661495,4.0021695 C8.6087695,2.2448095 5.7595295,2.2448095 4.0021695,4.0021695 Z M8.1841495,4.6841495 L8.1841495,6.1841495 L9.6841495,6.1841495 L9.6841495,8.1841495 L8.1841495,8.1841495 L8.1841495,9.6841495 L6.1841495,9.6841495 L6.1841495,8.1841495 L4.6841495,8.1841495 L4.6841495,6.1841495 L6.1841495,6.1841495 L6.1841495,4.6841495 L8.1841495,4.6841495 Z"></path>
    </svg>
  );
  // 缩小按钮
  const zoomOutIcon = (
    <svg viewBox="0 0 16 16" fill="currentColor">
      <path d="M11.7803495,2.5879595 C14.0781495,4.8857295 14.2959495,8.4758195 12.4338495,11.0186495 L15.3158495,13.9016495 L13.9016495,15.3158495 L11.0186495,12.4338495 C8.4758195,14.2959495 4.8857295,14.0781495 2.5879595,11.7803495 C0.0495475,9.2419395 0.0495475,5.1263595 2.5879595,2.5879595 C5.1263595,0.0495475 9.2419395,0.0495475 11.7803495,2.5879595 Z M4.0021695,4.0021695 C2.2448095,5.7595295 2.2448095,8.6087695 4.0021695,10.3661495 C5.7595295,12.1234495 8.6087695,12.1234495 10.3661495,10.3661495 C12.1234495,8.6087695 12.1234495,5.7595295 10.3661495,4.0021695 C8.6087695,2.2448095 5.7595295,2.2448095 4.0021695,4.0021695 Z M9.6841495,6.1841495 L9.6841495,8.1841495 L4.6841495,8.1841495 L4.6841495,6.1841495 L9.6841495,6.1841495 Z"></path>
    </svg>
  );
  const errorIcon = (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" x="24" y="24" width="72" height="72" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M30 84V36H90V84H30Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M39 55.5H81V58.5H39V55.5Z" fill="#C1CCDD" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M39 66H69V69H39V66Z" fill="#C1CCDD" />
      <rect x="30" y="36" width="60" height="10.5" fill="#C1CCDD" />
      <rect opacity="0.01" x="72" y="66" width="30" height="30" fill="white" />
      <circle cx="87" cy="81" r="10.5" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M87 69C80.373 69 75 74.373 75 81C75 87.627 80.373 93 87 93C93.627 93 99 87.627 99 81C99 74.373 93.627 69 87 69ZM88.5 87V84H85.5V87H88.5ZM88.5 75V82.5H85.5V75H88.5Z" fill="#006EFF" />
    </svg>
  );

   // 放大按钮
   const leftIcon = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 8C12.5 9.04943 12.1408 10.0149 11.5386 10.7803L13.1113 12.016C13.9811 10.9105 14.5 9.51584 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C6.25351 1.5 4.66789 2.1888 3.5 3.30957V2.03333C3.5 1.84665 3.5 1.75331 3.46367 1.682C3.43171 1.61928 3.38072 1.56829 3.318 1.53633C3.24669 1.5 3.15335 1.5 2.96667 1.5H2.03333C1.84665 1.5 1.75331 1.5 1.682 1.53633C1.61928 1.56829 1.56829 1.61928 1.53633 1.682C1.5 1.75331 1.5 1.84665 1.5 2.03333V7H6.46667C6.65335 7 6.74669 7 6.818 6.96367C6.88072 6.93171 6.93171 6.88072 6.96367 6.818C7 6.74669 7 6.65335 7 6.46667V5.53333C7 5.34665 7 5.25331 6.96367 5.182C6.93171 5.11928 6.88072 5.06829 6.818 5.03633C6.74669 5 6.65335 5 6.46667 5H4.64584C5.46982 4.07938 6.66725 3.5 8 3.5C10.4853 3.5 12.5 5.51472 12.5 8Z" fill="#8A8A8A"/>
      <path d="M7.03092 14.4281L7.32659 12.4501C7.5452 12.4828 7.76997 12.4999 8 12.4999C8.23003 12.4999 8.4548 12.4828 8.67341 12.4501L8.96908 14.4281C8.65292 14.4754 8.32932 14.4999 8 14.4999C7.67068 14.4999 7.34708 14.4754 7.03092 14.4281Z" fill="#8A8A8A"/>
      <path d="M10.3755 14.0521C10.9849 13.8128 11.5494 13.484 12.0528 13.082L10.8048 11.5191C10.4555 11.7981 10.0648 12.0254 9.64426 12.1906L10.3755 14.0521Z" fill="#8A8A8A"/>
      <path d="M1.94775 10.3754C2.18712 10.9848 2.51588 11.5492 2.91787 12.0527L4.48075 10.8047C4.20179 10.4554 3.97446 10.0647 3.80929 9.64415L1.94775 10.3754Z" fill="#8A8A8A"/>
      <path d="M3.9472 13.082C4.45064 13.484 5.01512 13.8128 5.62452 14.0521L6.35574 12.1906C5.93523 12.0254 5.54452 11.7981 5.19517 11.5191L3.9472 13.082Z" fill="#8A8A8A"/>
    </svg>
  );

  const rightIcon = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 7C2.5 8.04943 2.85922 9.01495 3.46142 9.78031L1.88871 11.016C1.01888 9.91048 0.5 8.51584 0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C8.74649 0.5 10.3321 1.1888 11.5 2.30957V1.03333C11.5 0.846649 11.5 0.753307 11.5363 0.682003C11.5683 0.619282 11.6193 0.568289 11.682 0.536331C11.7533 0.5 11.8466 0.5 12.0333 0.5H12.9667C13.1534 0.5 13.2467 0.5 13.318 0.536331C13.3807 0.568289 13.4317 0.619282 13.4637 0.682003C13.5 0.753307 13.5 0.846649 13.5 1.03333V6H8.53333C8.34665 6 8.25331 6 8.182 5.96367C8.11928 5.93171 8.06829 5.88072 8.03633 5.818C8 5.74669 8 5.65335 8 5.46667V4.53333C8 4.34665 8 4.25331 8.03633 4.182C8.06829 4.11928 8.11928 4.06829 8.182 4.03633C8.25331 4 8.34665 4 8.53333 4H10.3542C9.53018 3.07938 8.33275 2.5 7 2.5C4.51472 2.5 2.5 4.51472 2.5 7Z" fill="#8A8A8A"/>
      <path d="M7.96908 13.4281L7.67341 11.4501C7.4548 11.4828 7.23003 11.4999 7 11.4999C6.76997 11.4999 6.5452 11.4828 6.32659 11.4501L6.03092 13.4281C6.34708 13.4754 6.67068 13.4999 7 13.4999C7.32932 13.4999 7.65292 13.4754 7.96908 13.4281Z" fill="#8A8A8A"/>
      <path d="M4.62452 13.0521C4.01512 12.8128 3.45064 12.484 2.9472 12.082L4.19517 10.5191C4.54452 10.7981 4.93523 11.0254 5.35574 11.1906L4.62452 13.0521Z" fill="#8A8A8A"/>
      <path d="M13.0522 9.37537C12.8129 9.98476 12.4841 10.5492 12.0821 11.0527L10.5193 9.80471C10.7982 9.45536 11.0255 9.06466 11.1907 8.64415L13.0522 9.37537Z" fill="#8A8A8A"/>
      <path d="M11.0528 12.082C10.5494 12.484 9.98488 12.8128 9.37548 13.0521L8.64426 11.1906C9.06477 11.0254 9.45548 10.7981 9.80483 10.5191L11.0528 12.082Z" fill="#8A8A8A"/>
    </svg>
  )

  function scrollToTarget(target) {
    if (imgList?.length <= 0 || target < 0) return
    const currentScroll = imageBoxRef.current.scrollTop;
    let targetHeight = imgList[target - 1].y || 0;
    scrollTo(targetHeight - currentScroll)
  }
  return (
    <>
      {
        isError ?
          <div className={`unv-thumbnail-preview center ${isShow ? 'active' : 'hidden'}`} style={style} ref={imageBoxRef}>
            <div className="thumbnail-errorcontent">
              <div className="thumbnail-errorcontent__img">
                {errorIcon}
              </div>
              <div className="thumbnail-errorcontent__msg">
                {errorMsg}
              </div>
              <Button type="link" onClick={onUpload}>重新上传</Button>
            </div>
          </div> :
          <>
            <div 
            className={`unv-thumbnail-preview ${isShow ? 'active' : 'hidden'}`} 
            style={style} ref={imageBoxRef}
            id="imageBoxRef">
              <Stage 
                  className={`canvas-container ${canvasLayout}`} 
                  style={{
                    // width:rotateInfo.width,
                    // height:rotateInfo.height
                  }}
                  height={rotateInfo.height} 
                  width={rotateInfo.width}
                 >
                <Layer
                rotation={rotateInfo.rotation}
                offsetX={rotateInfo.offsetX}
                offsetY={rotateInfo.offsetY}
                >
                  {
                    imgList.map((item, index) => {
                      return <LayerImage
                        style={{transform: `rotate(${curDeg * 90}deg)`}}
                        key={index}
                        width={item.width}
                        height={item.height}
                        src={item.src}
                        y={item.y}
                        x={item.x}
                      />
                    })
                  }
                  {
                    !loading && rects.length > 0 && rects.map((rect, i) => (
                      <Line
                      style={{transform: `rotate(${curDeg * 90}deg)`}}
                        key={i}
                        onClick={() => {
                          if(rect.cantSelect ) return
                          onSelect && onSelect(rect.key);
                        }}
                        points={rect.points}
                        fill={selectRect && rect.key  && selectRect === rect.key ? (rect.activeFill || 'rgba(0, 110, 255, 0.12)') : (rect.fill || 'transparent') } 
                        stroke={selectRect && rect.key  && selectRect === rect.key ? (rect.activeStoke || '#006eff') : (rect.stroke || '#AACFFF')}
                        strokeWidth={selectRect && rect.key  && selectRect === rect.key ? rect.activeStrokeWidth || 2 : rect.stokeWidth || 1}
                        closed={true}
                      ></Line>
                    ))
                  }
                </Layer>
              </Stage>
            </div>
            <div className={`unv-thumbnail-preview__toolbar`} style={{ right: `calc(50% - ${showPdfPageControl ? '121px' : '48px'})` }}>
              {
                showPdfPageControl && <div className={`unv-thumbnail-preview__toolbar__pdfpreview`}>
                  <Icon className={`pre ${currentPage <= 1 ? 'disable' : ''}`} name="direction-bottom" onClick={() => {
                    if (currentPage <= 1) {
                      setCurrentPage(1)
                    } else {
                      setCurrentPage(Number(currentPage) - 1)
                    }
                  }} />
                  <span className={`currentPage`}>
                    <Input value={inputVal}
                      style={{ width: '32px' }}
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          if (!NumberReg.test(inputVal)) {
                            setInputVal(currentPage);
                            return;
                          };
                          if (inputVal > totalPage) {
                            setCurrentPage(totalPage)
                          } else if (inputVal <= 1) {
                            setCurrentPage(1)
                          } else {
                            setCurrentPage(inputVal)
                          }
                        }
                      }}
                      onBlur={
                        (e) => {
                          if (!NumberReg.test(inputVal)) {
                            setInputVal(Number(currentPage));
                            return
                          };
                          if (inputVal > totalPage) {
                            setCurrentPage(totalPage)
                          } else if (inputVal <= 1) {
                            setCurrentPage(1)
                          } else {
                            setCurrentPage(Number(inputVal))
                          }
                        }
                      }
                      onChange={(e) => {
                        // if (!NumberReg.test(e.target.value)) return;
                        setInputVal(e.target.value);
                      }} />
                  </span>
                  <span className={`split`} >/</span>
                  <span className={`totalPage`}>{totalPage}</span>
                  <Icon className={`next ${currentPage >= totalPage ? 'disable' : ''}`} name="direction-bottom" onClick={() => {
                    if (currentPage >= totalPage) {
                      setCurrentPage(totalPage)
                    } else {
                      setCurrentPage(Number(currentPage) + 1)
                    }
                  }} />
                </div>
              }
              {showZoomInIcon && <Tooltip title="放大"><Button disabled={sizes.width >= 180} type="link-weak" prefixIcon={zoomInIcon} onClick={onZoomIn} /></Tooltip>}
              {showZoomOutIcon && <Tooltip title="缩小"><Button disabled={sizes.width <= 20} type="link-weak" prefixIcon={zoomOutIcon} onClick={onZoomOut} /></Tooltip>}
              {showLeftIcon && <Tooltip title="向左旋转90度"><Button  type="link-weak" prefixIcon={leftIcon} onClick={() => onDeg(-1)} /></Tooltip>}
              {showRightIcon && <Tooltip title="向右旋转90度"><Button  type="link-weak" prefixIcon={rightIcon} onClick={() => onDeg(1)} /></Tooltip>}

              
            </div>
          </>
      }

    </>
  );
};
ThumbnailPreview.defaultProps = {
};

export default ThumbnailPreview;
