import React, { useState, useImperativeHandle, useRef } from 'react';
import { Button, Input, Msg, Upload,Tooltip,Icon } from "@tencent/universe-ui/build/lib";
import { isLogin } from '../../utils/index.js';
const URL_EXP = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;

const fileTypeUploadMsg = {
  'ofd':{
    name:'OFD',
    tips:'OFD票据'
  },
}
const FileToBase64 = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
};

function getFileType(url,acceptList,uploadExtList = []) {
  let fileList = uploadExtList.concat(acceptList);
  let acList = fileList.filter((i) => { return url.indexOf(i) > -1} )
  //获取文件类型
  return acList.length > 0 ? acList[acList.length - 1] : ''
}


export default ({curItem, acceptList = ['jpg', 'jpeg', 'png'], prefix = "Image", onSuccess, maxLength = 7, cRef }) => {
  useImperativeHandle(cRef, () => ({
    onUpload: () => {
      clickBtn?.current.click()
    }
  }));
  const [progress, setProgress] = useState(null);
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState('');
  const [visible, setVisible] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const clickBtn = useRef();


  const beforeUpload = (file, fileList, isAccepted, reasons) => {
    return new Promise(async (resolve, reject) => {
      isLogin();
      console.log('before upload');
      const imageBase64 = await FileToBase64(file);
      if (!file) {
        Msg.error({
          message: '请选择文件'
        });
        return reject()
      };
      const ext = file.name.split('.').pop().toLowerCase();
      if (!acceptList.includes(ext)) {
        Msg.error({
          message: curItem.uploadExtErrorMsg ? <>体验页面不支持{ext}格式，请点击<a style={{color:'#006eff'}} target="_blank" href={curItem.url}>{curItem.title}</a>调用API接口</>  : '文件格式不正确',
        });
        return reject()
      }
      if (imageBase64?.length > maxLength * 1024 * 1024) {
        Msg.error({
          message: `文件大小不能超过${maxLength}M`
        });
        return reject()
      }
      let _url = imageBase64;
      console.log('imageBase64',ext)
      if (prefix === 'OfdFile') {
        _url = imageBase64.replace('data:application/octet-stream;base64,', '')
      } else if (ext === 'bmp') {
        _url = imageBase64.replace('data:image/bmp;base64,', '')
      }else if (['docx','doc','xlsx','xls'].includes(ext)) {
        _url = imageBase64.replace(/data.+?;base64,/, '')
      }
      console.log('imageBase64',_url)
      

        onSuccess && onSuccess({
          img:{
            type: 'base64',
            previewUrl: imageBase64,
            OriginUrl:_url,
            url: _url,
            base64prefix: '',
            prefix: `${prefix}Base64`,
            isExample: false,
            pageNum:1,
            fileType: ext,
            cantPreview:false,
          },
          type:ext,
        });
      return reject()
    })
  };

  const handleStart = (file, context) => {
    setStatus('validating');
    setProgress(null);
    setFile(file);
  };

  const handleProgress = (progress, context) => {
    if (progress !== null) {
      setProgress(progress);
    }
  };

  const handleSuccess = (result, context) => {
    setStatus('success');
  };

  const handleError = (error, context) => {
    setStatus('error');
  };

  return (
    <div className="ocr_upload__container">
      <div className="ocr_upload__width"
      >
        <Upload
          beforeUpload={beforeUpload}
          onStart={handleStart}
          onProgress={handleProgress}
          onSuccess={handleSuccess}
          onError={handleError}
        >
          <Tooltip title={`支持${acceptList.join('、')}等格式，大小不超过7M`}>
          <Button
            style={{ fontWeight: 600,borderRadius: 3 }}
            ref={clickBtn}
            onClick={async (event) => {
              await isLogin(event);
            }}
            // type="hole"
            width="full"
          >
            上传本地文件
          </Button>
          </Tooltip>
        </Upload>
        <div className="unv-upload__btn-extra">
          <span className="unv-upload__btn-extra__tips">
            或
          </span>
          <Input style={{width: "100%"}} value={imgUrl} onChange={(e) => { setImgUrl(e.target.value) }} placeholder={`输入在线图片或PDF的URL,支持${acceptList.join('、')}等格式，大小不超过7M`} />
          <Button className="upload_btn" onClick={async () => {
            let ext = getFileType(imgUrl,acceptList,curItem.uploadExtList) ;
            console.log('getFileType ext',ext)
            if (!URL_EXP.test(imgUrl)) {
              Msg.error({
                message: '请输入正确的url地址'
              });
            } else if (acceptList.filter((i) => { return ext === (i).toLowerCase() }).length <= 0) {
              Msg.error({
                message: curItem.uploadExtErrorMsg ? <>体验页面不支持{ext}格式，请点击<a style={{color:'#006eff'}} target="_blank" href={curItem.url}>{curItem.title}</a>调用API接口</>  : '文件格式不正确',
              });
            } else {
              onSuccess && onSuccess({
                img:{
                  type: 'url',
                  url: imgUrl,
                  OriginUrl:imgUrl,
                  prefix: `${prefix}Url`,
                  fileType: ext,
                  pageNum:1,
                  isExample: false,
                  cantPreview:false,
                },
                type: ext,
              });
              setImgUrl('');
              setVisible(false);
            }

          }}>
            <Icon name="search"></Icon></Button>
        </div>
      </div>
    </div>

  );
};