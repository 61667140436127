
export const navList = [
  { text: '体验中心', key: '1', href: "", isActive: true, hasSub: false },
  { text: '产品控制台', key: '2', href: "", isActive: false, hasSub: false },
  { text: '产品文档', key: '3', href: "", isActive: false, hasSub: false },
]
export const OcrConsoleUrl = 'https://console.cloud.tencent.com/ocr';

export const errPdf = 'https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/file_fail.png'

export const cosUrl = 'https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/';
