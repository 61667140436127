import React, { useState, useEffect, useRef } from "react";
import { navList, OcrConsoleUrl,cosUrl } from '../const/menuData';
import navItemsTest from '../const/menu.json' ;
import ChatOcr from './ChatOcr.jsx';
import GeneralBasicOCR from './GeneralBasicOCR.jsx';
import OcrUpload from './common/Upload.jsx';

import ThumbnailPreview from './common/ThumbnailPreview.jsx';
import {
  ImageOptimize,
  Nav,
  Layout,
  ThumbnailLayout,
  Menu,
  Thumbnail,
  Search
} from "@tencent/universe-ui/build/lib";
import { getQueryString, filterTree,getRectPosition,checkIsInWhite,getWhiteList } from '../utils/index';
import { getProductList } from '../utils/api';
import useSmoothScroll from 'react-smooth-scroll-hook';

const { Body, Content, Sider } = Layout;

function OcrLayout() {
  const [range, setRange] = useState(50);
  const [isLoading, setIsLoading] = useState(false);
  const [navItems, setNavItems] = useState([]);
  const [defaultNavItems, setDefaultNavItems] = useState([]);
  const [imgPreType, setImgPreType] = useState('preview'); // preview || col || split
  const [openDropdow, setOpenDropdow] = useState(false);
  const [showImgList,setShowImgList] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  const [imglist, setImglist] = useState([]);
  const [uploadImglist, setUploadImglist] = useState([]);
  const [curItem, setCurItem] = useState(''); //  当前选中action
  const [curImg, setCurImg] = useState(null);
  const [result, setResult] = useState(null);
  const [prefix, setPrefix] = useState('Image');
  const [totalPage, setTotalPage] = useState(1);
  const [selectRectKey,setSelectRectKey]= useState('');
  const [showThumListBtn,setShowThumListBtn]= useState(false);

  const [isImageError, setIsImageError] = useState(true);
  const [imgSize, setImgSize] = useState({
    width: 0,
    height: 0
  })
  const [rects, setRects] = useState([]);
  const [rectDocuments, setRectDocuments] = useState([]);
  const uploadRef = useRef();
  const imgPreRef = useRef();
  const GeneralBasicOCRRef = useRef();
  const domRef = useRef(document.body);
  const thumbnailRef = useRef();
  const { scrollTo } = useSmoothScroll({
    ref: domRef,
    speed: 100,
    direction: 'x',
  });
  const [selected, setSelected] = useState(""); //  当前选中菜单

  const getMenuItemProps = item => ({
    selected: selected === item.action,
    onClick: () => {
      setSelected(item.action);
      setConfig(item)
    }
  });

  // 切换图片/项目时，清空rect
  useEffect(() => {
    setRectDocuments([]);
    setSelectRectKey('');
    setRects([]);
    setShowImgList(true);
  }, [curItem, curImg])

  useEffect(() => {
    if (imgSize.width > 0 && imgSize.height > 0 && rectDocuments.length > 0) {
     // TODO: 增加pdf位置
      const _rects = rectDocuments.map(r => {
        const OriginImgWidth = r.OriginImgWidth || imgSize.originWidth
        const radio = imgSize.width / OriginImgWidth;
        return {
          ...r,
          visible: true,
          // TODO: 边框 - 1
          points: r.points.map(i => i * radio)
        }
      })
      setRects(_rects);
    } else {
      setRects([]);
    }
  }, [imgSize, rectDocuments])

  useEffect(() => {
    async function func() {
      const res = await getProductList()

      if (res?.config?.defaultConfig) {
        let _navItems = res.config.defaultConfig 
        // let _navItems = navItemsTest.defaultConfig;
        let _whiteList = [];
        _navItems.flat().map(i => {
          i.children.map(c =>{
            if(c.whiteList?.length > 0){
              _whiteList = _whiteList.concat(c.whiteList)
            }
          })
        });
        console.log('_navItems whiteList',_whiteList)
        const whiteListRule = await checkIsInWhite(_whiteList);
        console.log('_navItems checkIsInWhite',whiteListRule)
        

        _navItems = _navItems.flat().map(i => {
          return {
            ...i,
            children:i.children.filter(c => {
              // 配置了直接不显示
              if(typeof(c.IsHide) === 'boolean'){
                return !c.IsHide
                // 配置通过白名单显示
              }else if(typeof(c.IsHide) === 'object'){
                return whiteListRule[c.IsHide]?.length > 0
              }else{
                return true
              }
            })
          }
        });
        setDefaultNavItems(_navItems);
        setNavItems(_navItems);
        const action = getQueryString('action');
        const list = _navItems?.map(i => i.children).flat();
        const result = list?.find(i => {
          return i.action === action;
        });
        const _result = result ? result : _navItems[0].children[0];
        setConfig(_result)
        setSelected(_result.action)
      }

    }
    func();

  }, [])

  const onClickMenu = (res) => {
    switch (res.key) {
      case '1':
        setOpenDropdow(!openDropdow);
        break;
      case '2':
        window.open(curItem.consoleUrl || OcrConsoleUrl, "_blank");
        break;
      case '3':
        if (curItem.url) {
          window.open(curItem.url, "_blank");
        }
        break;
    }
  }
  const setConfig = (_result) => {
    const prefix = _result.prefix || 'Image';
    setPrefix(prefix);
    setUploadImglist([]);
    const imgList = _result?.imglist?.map((i, index) => {
      if(typeof i === 'string'){
        return {
          previewUrl:i.indexOf('https') > -1 ? i : cosUrl + i,
          url: i.indexOf('https') > -1 ? i : cosUrl + i, //  图片预览地址
          OriginUrl:i.indexOf('https') > -1 ? i : cosUrl + i,  //  图片原始预览地址
          cantPreview: _result?.fakeImglist?.length > 0 ? true : false,
          type: 'url',
          isExample: true,
          pdfImgList:[],
          fileType: i?.split('?')[0].split('.').pop().toLowerCase(),
          prefix: _result?.ImageParams ? _result?.ImageParams : `${prefix}Url`,
          param: _result?.fakeImglist ? (i.indexOf('https') > -1 ? _result?.fakeImglist[index] : cosUrl + _result?.fakeImglist[index]) : ''
        }
      }else{
        return {
          previewUrl:i.url.indexOf('https') > -1  ? i.url : cosUrl + i.url  ,
          url: i.url.indexOf('https') > -1  ? i.url : cosUrl + i.url , //  图片预览地址
          defaultConfig:i.defaultConfig,
          OriginUrl: i.url.indexOf('https') > -1 ? i.url : cosUrl + i.url , //  图片原始预览地址
          cantPreview: _result?.fakeImglist?.length > 0 ? true : false,
          type: 'url',
          isExample: true,
          pdfImgList:[],
          fileType: i.url.split('?')[0].split('.').pop().toLowerCase(),
          prefix: _result?.ImageParams ? _result?.ImageParams : `${prefix}Url`,
          param: _result?.fakeImglist ? (i.indexOf('https') > -1 ? _result?.fakeImglist[index] : cosUrl + _result?.fakeImglist[index]) : ''
        }
      }
      })
      console.log('imgList',imgList);
    setImglist(imgList);
    setCurImg(imgList[0]);
    setTotalPage(1);
    setResult(imgList[0]);
    setImgPreType(_result?.action === 'ImageEnhancement' ? 'split' : 'preview');
    setCurItem(_result);
    // 处理图片显示
    if(domRef?.current?.clientWidth < imgList?.length * 94){
      setShowThumListBtn(true)
    }else{
      setShowThumListBtn(false)
    }
  }
  return (
    <div className="pg-recognize">
      <Nav
        openDropdow={false}
        menuData={navList}
        curItem={curItem}
        onClickMenu={onClickMenu}
      />
      <Layout flex>
        <Body>
          {/* 内容区 start */}
          <Sider flex style={{ width: showMenu ? '280px' : 0, transition: 'all 0.3s', overflowX: 'hidden' }}>
            <div className="search_container">
              <Search placeholder="请描述所识别场景或材料名称" onSearch={e => {
                if (e) {
                  let searchList = filterTree(defaultNavItems, (item) => {
                    return item.title.includes(e) || item.text?.includes(e) || item.action?.includes(e)
                  });
                  setNavItems(searchList);

                } else {
                  setNavItems(defaultNavItems);
                }
              }} />
            </div>

            <Menu style={{ overflowY: 'scroll' }} defaultCollapsed={false} collapsed={false}>
              {navItems.map(menu => (
                <Menu.SubMenu title={menu.title} defaultOpened>
                  {
                    menu.children.map(item => (
                      <Menu.Item title={item.title} {...getMenuItemProps(item)} />
                    ))
                  }
                </Menu.SubMenu>
              ))}
            </Menu>
          </Sider>
          <div className={`menu__icon ${showMenu ? 'isActive' : ''}`} onClick={() => {
            setShowMenu(!showMenu)
          }}>
            <div className="menu__icon_tip">
              <span></span>
            </div>
          </div>
          <Content flex style={{ background: '#F7F8F9' }} >
            <Content.Header
              align="center"
              title={curItem.title}
            />
            <Content.Body>
              {/* <ThumbnailLayout > */}
                <ThumbnailLayout.Content flex>
                  <div className="preview_container" >
                    {
                      // curImg && !isImageError && !isLoading && <>
                       <>
                        {
                          imgPreType === 'preview' ? <ThumbnailPreview
                            isShow={curImg && !isImageError && !isLoading }
                            cRef={imgPreRef}
                            curItem={curItem}
                            showImgList={showImgList}
                            rects={rects}
                            selectRect={selectRectKey}
                            style={{ background: '#eef0f3' }}
                            onUpload={() => {
                              uploadRef.current.onUpload();
                            }}
                            onSelect={
                              (val) => {
                                setSelectRectKey(val);
                              }
                            }
                            onSize={(res) => {
                              setImgSize(res)
                            }}
                            imageList={curImg?.pdfImgList?.length > 0 ? curImg.pdfImgList : []}
                            imageUrl={ curImg?.previewUrl || curImg?.url}
                          /> : <>
                            <ImageOptimize type="split" defaultRange={range} getRange={e => {
                              setRange(e)
                            }} imgList={[`data:image/png;base64,${result.url}`, curImg.url,]} tips={['文本图像增强后', '原图']} />
                          </>
                        }
                      </>
                    }
                  </div>
                  <div className="imgList_scrollView-container" >
                  <div className="forshow middle">
                      <div className="flex"></div>
                      <div className={`${showImgList ? 'isActive' : ''} img_setH`} onClick={() => {
                        setShowImgList(!showImgList,function() {
                        })
                      }}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.33735 4.0946C8.24419 4.00056 8.12114 3.96277 8.00073 3.97068C7.87944 3.96277 7.75552 4.00056 7.66235 4.0946L3.63081 8.12615C3.4603 8.29665 3.4603 8.57263 3.63081 8.74314C3.80132 8.91365 4.0773 8.91365 4.2478 8.74314L8.00073 4.99109L11.7519 8.74314C11.9224 8.91365 12.1993 8.91365 12.3698 8.74314C12.5394 8.57263 12.5394 8.29665 12.3698 8.12615L8.33735 4.0946ZM7.99985 7.13035C7.87857 7.12156 7.75464 7.16023 7.66147 7.25251L3.63081 11.2841C3.4603 11.4546 3.4603 11.7305 3.63081 11.9019C3.80132 12.0733 4.07642 12.0716 4.24692 11.9028L7.99985 8.14988L11.7519 11.9028C11.9224 12.0716 12.1993 12.0733 12.3698 11.9028C12.5394 11.7305 12.5394 11.4554 12.3698 11.2849L8.33648 7.25339C8.24419 7.16023 8.12114 7.12156 7.99985 7.13035Z" fill="#9B9A9A"/>
                        </svg>
                      </div>
                      <div className="flex"></div>
                    </div>
                    <div className={`${showImgList ? '' : 'isHide'} imgList_scrollView`} ref={domRef} >
                      <div 
                      onTransitionEnd={(e) => {
                        console.log('onPreChange onTransitionEnd',curImg)
                        imgPreRef?.current?.onPreChange(curImg?.cantPreview ? '' : curImg?.previewUrl || curImg?.url)
                      }} 
                      className="unv-thumbnail-list" ref={thumbnailRef} >
                        {
                          [...uploadImglist].map((i, index) => (
                            <>
                              {
                                !isImageError && <Thumbnail
                                  key={index}
                                  onChoose={() => {
                                    console.log('Thumbnail',i);
                                    if (isLoading) return;
                                    setCurImg(i);
                                    setTotalPage(i.pdfImgList?.length || 1);
                                    setResult(i);
                                    GeneralBasicOCRRef?.current?.start({ image: i })
                                  }}
                                  fileType={i.fileType}
                                  active={curImg?.url === i.url || curImg?.OriginUrl === i.OriginUrl}
                                  imageUrl={i.previewUrl || i.url}
                                />
                              }
                            </>
                          ))
                        }
                        {
                          imglist.map((i, index) => (
                            <Thumbnail
                              key={index}
                              onChoose={() => {
                                if (isLoading) return;
                                console.log('onChoose',i)
                                setCurImg(i);
                                setTotalPage(i.pdfImgList?.length || 1);
                                setResult(i);
                                GeneralBasicOCRRef?.current?.start({ image: i , config:i.defaultConfig})
                              }}
                              fileType={i.fileType}
                              active={curImg?.url === i.url || curImg?.OriginUrl === i.OriginUrl}
                              imageUrl={i.previewUrl || i.url}
                            />
                          ))
                        }
                      </div>
                    </div>
                    <div className={`${showImgList && showThumListBtn ? 'isActive' : 'isHide'} imgList_scrollView__btn`} onClick={() => {
                      scrollTo(200)
                    }}></div>

                  </div>


                  <OcrUpload
                    cRef={uploadRef}
                    prefix={prefix}
                    acceptList={curItem.acceptList}
                    curItem={curItem}
                    onSuccess={({img,type,imgList}) => {
                      console.log('OcrUpload',img);
                        setIsImageError(true);
                        setUploadImglist([img]);
                        GeneralBasicOCRRef?.current?.start({ image: img})
                    }} />
                </ThumbnailLayout.Content>
                {/* 缩略图内容 end */}
              {/* </ThumbnailLayout> */}
            </Content.Body>
          </Content>
          {/* 内容区 end */}

          {/* 侧边栏 start */}
          <Sider flex style={{ width: '35%' }}>
            {
              curItem.isChat ? <ChatOcr
                image={curImg}
                detail={curItem}
                onImageError={(res) => {
                  setIsImageError(res);
                  if (res) {
                    // 图片审核不通过
                    setUploadImglist([]);
                    setCurImg('');
                  }
                }}
              /> : <GeneralBasicOCR
                selectRect={selectRectKey}
                totalPage={totalPage}
                onSelect={(textVal,rectVal) => {
                  setSelectRectKey(rectVal);
                }}
                cRef={GeneralBasicOCRRef}
                // onLoading={res => setIsLoading(res)}
                onResult={({image,rsp,requestParams}) => {
                  setIsLoading(true);
                  const pdfList = rsp.auditData?.length > 0 && rsp.auditData[0].ImageUrl ? rsp.auditData?.map(i =>i.ImageUrl) : [];
                  console.log('handleSetStageWidth pdfList',pdfList,rsp.auditData)
                  setTotalPage(rsp.auditData?.length || 1);
                  if(curItem.action === 'QuestionOCR' || curItem.action === 'QuestionSplitOCR'){
                    console.log('handleSetStageWidth rsp',rsp,image);
                    let img = pdfList.length > 0 ? pdfList[0]: image.url;
                    if(rsp.QuestionInfo[0].ImageBase64){
                      img = `data:image/png;base64,` + rsp.QuestionInfo[0].ImageBase64;
                    }
                    const _curImg = {
                      ...image,
                      cantPreview: false,
                      pdfImgList: [],
                      previewUrl:pdfList.length > 0 ? pdfList[0] :  img //  默认识别第一张pdf
                    }
                    setCurImg(_curImg)
                    if(uploadImglist.length > 0 && uploadImglist[0]?.url == image.url ){
                      setUploadImglist([_curImg]);
                    }
                    imgPreRef?.current?.onPreChange(img)
                  }else{
                    const _curImg = {
                      ...image,
                      pdfImgList:pdfList,
                      previewUrl:pdfList.length > 0 ? pdfList[0] : image.previewUrl || image.url,
                      cantPreview: false,
                    }
                    setCurImg(_curImg);
                    if(uploadImglist.length > 0 && uploadImglist[0]?.url == image.url ){
                      setUploadImglist([_curImg]);
                    }
                    console.log('onPreChange result',image)
                    imgPreRef?.current?.onPreChange(image.url)
                  }
                  setTimeout(() => {
                    let _rects = [];
                    console.log('getRectPosition',rsp);
                    if(curItem.action === 'SmartStructuralPro'){
                      let smartListType = curItem?.rectConfig?.useWordList && rsp?.WordList?.length > 0 ? 'WordListRectConfig' : 'rectConfig'
                      _rects = getRectPosition({
                        data:rsp,
                        config:curItem[smartListType],
                        isMulPage: pdfList.length  > 1
                      });
                    }else{
                      _rects = getRectPosition({
                        data:rsp,
                        config:curItem.rectConfig,
                        isMulPage: pdfList.length  > 1
                      });
                    }
                    setRectDocuments(_rects);
                    setShowImgList(false);
                  },0)
                  setIsLoading(false);
                }}
                onImageError={(res) => {
                  setIsImageError(res);
                  if (res) {
                    // 图片审核不通过
                    setUploadImglist([]);
                    setCurImg('');
                  }
                }}
                onChangeImg={
                  (img,params) => {
                    const _curImg = {
                      ...curImg,
                      url:img,
                      previewUrl:img,
                      OriginUrl:img
                    }
                    setCurImg(_curImg);
                    console.log('onChangeImg',_curImg)
                    GeneralBasicOCRRef?.current?.start({ image: _curImg,config:params })
                  }
                }
                onChangeImageEnhancement={(res) => {
                  setResult({ //  文本图像增强
                    url: res.url
                  });
                  setImgPreType(res.type);
                }}
                detail={curItem}
                image={curImg} />
            }

          </Sider>
          {/* 侧边栏 end */}
        </Body>
      </Layout>
    </div>
  );
}

export default OcrLayout;
